import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePaginatedService } from './base-paginated.service';

@Directive()
export abstract class BasePaginatedComponent
  implements AfterViewInit, OnDestroy
{
  protected abstract paginatedService: BasePaginatedService<any>;

  private route = inject(ActivatedRoute);
  protected router = inject(Router);
  private routeSubscription?: Subscription;

  private cd = inject(ChangeDetectorRef);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.paginator.pageSize = this.paginatedService.pageSize;

    this.paginator.page
      .pipe(
        switchMap(() => {
          return this.loadData(this.paginatedService.searchFilter);
        })
      )
      .subscribe((response) => {
        this.paginatedService.onLoad(response);
        this.setUpPageIndexAndLength();
      });

    if (!this.routeSubscription) {
      this.routeSubscription = this.route.queryParams.subscribe((params) => {

        const newSearchFilter = params['search']
        
        if(newSearchFilter != this.paginatedService.searchFilter){
          this.paginator.pageIndex = 0;
        }
        // if filter is the same and already has results, stop
        else if(this.paginatedService.dataSource.data.length>0){
          this.setUpPageIndexAndLength();
          return;
        }

        const clearSearch = params['clearSearch'] === 'true'
        
        //if had filter and results
        if(!!this.paginatedService.searchFilter && !newSearchFilter && this.paginatedService.dataSource.data.length>0 && !clearSearch){
          this.router.navigate([], {queryParams:{search:this.paginatedService.searchFilter  }})
          this.setUpPageIndexAndLength();
          return;
        }

        this.paginatedService.searchFilter = newSearchFilter;

        this.loadData(this.paginatedService.searchFilter).subscribe((response) => {
          this.paginatedService.onLoad(response);
          this.setUpPageIndexAndLength();
        });
      });
    }

    this.cd.detectChanges();
  }

  loadData(search?: string) {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.paginatedService.getAll(pageIndex, pageSize, search);
  }

  setUpPageIndexAndLength() {
    this.paginator.length = this.paginatedService.totalItems;
    this.paginator.pageIndex = this.paginatedService.pageIndex;
  }
}

import { HttpEventType } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-video-dialog',
  standalone: true,
  imports: [
    MatDialogActions,
    MatButtonModule,
    MatDialogContent,
    MatDialogTitle,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './upload-video-dialog.component.html',
  styleUrl: './upload-video-dialog.component.scss',
})
export class UploadVideoDialogComponent {
  selectedFile: File | null = null;
  uploadProgress?: number;
  uploadMessage: string = '';
  fileName?: string;

  linkControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/),
  ]);

  apiService = inject(ApiService);

  readonly dialogRef = inject(MatDialogRef<UploadVideoDialogComponent>);
  readonly data = inject<{ videoType: VideoType }>(MAT_DIALOG_DATA);
  readonly videoTypes = VideoType;

  // Handle file input change event
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      this.selectedFile = file;

      this.fileName = file.name;
    } else {
      this.uploadMessage = 'Please select a valid video file.';
      this.selectedFile = null;
    }
  }

  // Upload the selected video file
  onUpload(): void {

    const formData = new FormData();
    formData.append('videoType', this.data.videoType); // Add the video type to the form data

    const link = this.linkControl.value;

    if (link) {
      formData.append('link', link);
    } else if (this.selectedFile) {
      formData.append('video', this.selectedFile);
    }

    this.uploadProgress = 0;
    this.apiService
      .post('storage/upload_video', formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.uploadProgress = Math.round(
              100 * (event.loaded / event.total)
            );
          } else if (event.type === HttpEventType.Response) {
            this.uploadProgress = 100;
            this.uploadMessage = 'Upload concluído!';
          }
        },
        error: (error) => {
          this.uploadMessage = `Upload failed: ${error.message}`;
          this.uploadProgress = 0;
        },
      });
  }

  close() {
    this.dialogRef.close(this.uploadProgress == 100);
  }
}

export enum VideoType {
  REVISION = 'revision',
  NEW_WORK = 'new_work',
}

import { CanActivateFn, Router, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AuthService } from './services/auth.service';
import { ColaboradoresComponent } from './views/colaboradores/colaboradores.component';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { ProvasComponent } from './views/provas/provas.component';
import { TurmaComponent } from './views/turma/turma.component';
import { AvalicaoesComponent } from './views/avalicaoes/avalicaoes.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { TeacherAssessmentComponent } from './views/teacher-assessment/teacher-assessment.component';
import { MapaDeRiscoComponent } from './views/mapa-de-risco/mapa-de-risco.component';
import { AlunosComponent } from './views/alunos/alunos.component';
import { AlunoComponent } from './views/aluno/aluno.component';
import { TarefasComponent } from './views/tarefas/tarefas.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { PlanejamentoComponent } from './views/planejamento/planejamento.component';
import { TimesheetsComponent } from './views/timesheets/timesheets.component';
import { PostsComponent } from './views/posts/posts.component';
import { FaltasConsecutivasComponent } from './components/faltas-consecutivas/faltas-consecutivas.component';

function featureGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router: Router = inject(Router);

    return authService.checkLogin().pipe(
      map(() => true),
      catchError(() => of(router.createUrlTree(['login'])))
    );
  };
}

function loginRedirectGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router: Router = inject(Router);

    return authService.checkLogin().pipe(
      map(() => router.createUrlTree([''])),
      catchError(() => of(true))
    );
  };
}

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginRedirectGuard()],
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [featureGuard()],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'colaboradores',
        component: ColaboradoresComponent,
        data: { searchable: true },
      },
      {
        path: 'provas',
        component: ProvasComponent,
      },
      {
        path: 'turma/:id',
        component: TurmaComponent,
      },
      {
        path: 'mapa-de-risco',
        component: MapaDeRiscoComponent,
        data: { searchable: true },
      },
      {
        path: 'avaliacoes',
        component: AvalicaoesComponent,
      },
      {
        path: 'avaliacoes/:month/:year/:teacherId',
        component: TeacherAssessmentComponent,
      },
      {
        path: 'notificacoes',
        component: NotificationsComponent,
      },
      {
        path: 'tarefas',
        component: TarefasComponent,
        // data: { searchable: true },
      },
      {
        path: 'alunos',
        component: AlunosComponent,
        data: { searchable: true },
      },
      {
        path: 'aluno/:id',
        component: AlunoComponent,
        data: { searchable: true, searchIn: 'alunos' },
      },
      {
        path: 'calendar',
        component: CalendarComponent,
      },
      {
        path: 'planejamento',
        component: PlanejamentoComponent,
      },
      {
        path: 'timesheets',
        component: TimesheetsComponent,
      },
      {
        path: 'posts',
        component: PostsComponent
      },
      {
        path: 'presenca',
        component: FaltasConsecutivasComponent
      }
    ],
  },
];

<div class="h-72 md:h-full">

  @if(this.barChartData){

    <canvas baseChart
    [data]="barChartData"
    [labels]="labels()"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [type]="barChartType">
  </canvas>
}
</div>
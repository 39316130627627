import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ITurma } from '../../models/turma.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DiarioComponent } from '../../components/diario/diario.component';
import { ChipComponent } from '../../components/chip/chip.component';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { getEnumValue } from '../../utils/get-enum-value.util';
import { enumToString } from '../../utils/enum-to-string.util';
import { StarsComponent } from '../../components/stars/stars.component';
import { MatButtonModule } from '@angular/material/button';
import { LiveBoardComponent } from '../../components/live-board/live-board.component';
import { StatisticComponent } from '../../components/statistic/statistic.component';
import { IAluno } from '../../models/aluno.model';
import { BarChartComponent } from '../../components/bar-chart/bar-chart.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ActivatedRoute } from '@angular/router';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { StudentsTableComponent } from '../../components/students-table/students-table.component';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { DiaryService } from '../../services/diary.service';
import { TurmaService } from '../../services/turma.service';
import { TurmaFeedbacksComponent } from '../../components/turma-feedbacks/turma-feedbacks.component';

@Component({
  selector: 'app-turma',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    DiarioComponent,
    ChipComponent,
    BarChartComponent,
    StarsComponent,
    MatButtonModule,
    StatisticComponent,
    LiveBoardComponent,
    LoadingComponent,
    NotFoundComponent,
    StudentsTableComponent,
    LogomarkComponent,
    TurmaFeedbacksComponent,
  ],
  templateUrl: './turma.component.html',
  styleUrl: './turma.component.scss',
})
export class TurmaComponent implements OnInit {
  protected getEnumValue = getEnumValue;
  protected enumToString = enumToString;

  protected eTurmaStatus = ETurmaStatus;
  protected eTurmaStatusColor = ETurmaStatusColor;

  protected turma?: ITurma;
  protected alunos?: IAluno[];

  private route = inject(ActivatedRoute);
  protected getTurmaStatusColor = getTurmaStatusColor;
  protected classroomId = this.route.snapshot.params['id'];

  private apiService = inject(ApiService);
  private diaryService = inject(DiaryService);
  protected turmaService = inject(TurmaService);

  protected attendance: { [key: string]: number } = {};

  protected averageAttendance?: number;
  protected assessmentPercentage?: number;

  protected get classroomProgress(): number | undefined {
    return this.diaryService.classroomProgress;
  }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.apiService
      .get<ITurma>(`classrooms/${this.classroomId}`)
      .subscribe((turma) => {
        this.turma = turma;
        this.alunos = turma.students;
        this.turmaService.students = turma.students;
      });

    this.apiService
      .get<typeof this.attendance>(
        `attendance/classroom/${this.classroomId}/students`
      )
      .subscribe((response) => {
        this.attendance = response;

        this.averageAttendance = this.calcularMedia(response);
      });

      this.getLastDaysAttendances()
  }

  protected attendanceGraph?: {
    data: number[],
    labels: string[]
  }

  getLastDaysAttendances() {
    this.apiService
      .getByFilters<typeof this.attendanceGraph>('attendance/lastdays', {
        classroom_id: this.classroomId,
      })
      .subscribe((response) => {
        this.attendanceGraph = response;
      });
  }

  private calcularMedia(obj: { [key: string]: number }): number {
    // Obtém os valores do objeto
    const valores = Object.values(obj);

    // Filtra os valores que são diferentes de zero
    const valoresValidos = valores.filter((valor) => valor !== 0);

    if (valoresValidos.length === 0) return 0;

    // Soma os valores válidos
    const soma = valoresValidos.reduce((acc, valor) => acc + valor, 0);

    // Calcula a média
    const media = soma / valoresValidos.length;

    return Math.round(media);
  }
}

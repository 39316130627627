<h1 matDialogTitle>{{data.name}}</h1>

<mat-dialog-content>

    <table mat-table [dataSource]="data.registros_de_falta" class="table !shadow-none">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
                <span>Dia</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-3">
                {{formatDate(student.date)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="present">
            <th mat-header-cell *matHeaderCellDef>
                <span>Presente</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-4">

                @if(student.present){
                <mat-icon class="text-green-600">check</mat-icon>
                }@else{
                <mat-icon class="text-red-600">close</mat-icon>
                }

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let student; columns: columns;">
        </tr>
    </table>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>Ok</button>
</mat-dialog-actions>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileQueryService {

  mediaQuery?: MediaQueryList;

  public sideNavCollapsed: boolean = false;
  
  get matches(): boolean {
    if(this.mediaQuery == undefined)
      return false;

    return this.mediaQuery.matches
  }

}

<div class="flex flex-col gap-4 card p-4 h-full">
    <h6>Feedbacks ({{feedbacks.length}})</h6>

    <div class="overflow-y-auto">
        <mat-form-field class="w-full" appearance="outline">
            <!-- <mat-label>Feedback</mat-label> -->
            <textarea matInput placeholder="Deixe um feedback..." rows="2" [(ngModel)]="comment"></textarea>
        </mat-form-field>

        @if(comment()){
        <div class="flex flex-row gap-4 justify-end mb-5">
            <button mat-button (click)="comment.set('')">Cancelar</button>
            <button mat-flat-button (click)="onSubmitFeedback()">Comentar</button>
        </div>
        }

        <div class="flex flex-col gap-6" [ngClass]="{'hidden': feedbacks.length === 0}">
            @for (feedback of feedbacks; track feedback) {
            <div class="flex flex-row gap-4">
                <app-profile-picture [user]="feedback.user??{}" [hideName]="true" size="small"
                    class="!w-8"></app-profile-picture>
                <div class="flex flex-col gap-2 w-fit">
                    <div class="flex flex-row gap-2 items-center">
                        <p class="font-medium text-sm">{{getShortName(feedback.user?.name)}}</p>
                        <p class="text-light text-xs">{{calcularTempoPassado(feedback?.created_at??'')}}</p>
                    </div>

                    <pre class="text-light whitespace-break-spaces " style="font-family: inherit;"
                        [innerText]="feedback.comment"></pre>

                </div>
            </div>
            }
        </div>
    </div>

</div>
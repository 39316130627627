import { inject, Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { IPaginatedResult } from '../models/paginatedResult.model';
import { finalize } from 'rxjs';

@Injectable()
export abstract class BasePaginatedService<DataSourceType> {
  abstract resourceUrl: string;

  protected apiService = inject(ApiService);
  public dataSource = new MatTableDataSource<DataSourceType>([]);
  get isLoading() {
    return this._isLoading;
  }

  searchFilter?: string;
  protected _isLoading: boolean = false;
  totalItems: number = 0;
  pageIndex: number = 0;
  notFound: boolean = false;
  pageSize: number = 8;

  filters: { [key: string]: string[] } = {};

  onLoad(data: IPaginatedResult<DataSourceType>) {
    this.dataSource.data = data.items;
    this.totalItems = data.total;
    this.notFound = data.total === 0;
  }

  getAll(pageIndex: number = 0, pageSize: number = this.pageSize, search: string = '') {
    this.pageIndex = pageIndex;

    this._isLoading = true;
    this.notFound = false;

    let params: any = {
      pageIndex,
      pageSize,
    };

    for (const key in this.filters){
      params[key] = this.filters[key]
    }

    if (search) params.search = search;

    return this.apiService
      .getByFilters<IPaginatedResult<DataSourceType>>(
        this.resourceUrl,
        params
      )
      .pipe(
        finalize(() => {
          this._isLoading = false;
        })
      );
  }
}

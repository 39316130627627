import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotificationService } from '../../services/notification.service';
import { MatMenuItem } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NgClass } from '@angular/common';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    MatIconModule,
    MatMenuItem,
    MatRippleModule,
    RouterModule,
    LoadingScreenComponent,
    NgClass,
    ProfilePictureComponent
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent implements OnInit {
  protected notificationService = inject(NotificationService);
  private route = inject(ActivatedRoute);

  protected isNotificationView: boolean = false;

  calcularTempoPassado = calcularTempoPassado

  ngOnInit(): void {
    this.isNotificationView =
      this.route.snapshot.url[0]?.path == 'notificacoes';

    if (this.isNotificationView) this.notificationService.getAll().subscribe();
  }
}

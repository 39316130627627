import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ExamsService } from '../../services/exams.service';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleExamDialogComponent } from '../../components/schedule-exam-dialog/schedule-exam-dialog.component';
import { ExamResultsDialogComponent } from '../../components/exam-results-dialog/exam-results-dialog.component';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { AuthService } from '../../services/auth.service';
import { ECargo } from '../../enums/e-cargo.enum';

export const PLACEMENT_TEST_ID = 14;

@Component({
  selector: 'app-provas',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    LoadingComponent,
    LogomarkComponent,
  ],
  templateUrl: './provas.component.html',
  styleUrl: './provas.component.scss',
})
export class ProvasComponent {
  displayedColumns: string[] = ['name', 'weight', 'symbol'];
  // dataSource = ELEMENT_DATA;

  // Properties
  private examsService = inject(ExamsService);
  private authService = inject(AuthService);

  // Variables
  stages: { stage_id: number; exam_count: number; stage_name: string }[] = [];
  showScheduleModal: boolean = false;

  // @ViewChild('scheduleModal') scheduleModal!: ScheduleExamModalComponent;
  // @ViewChild('scoreReportModal') scoreReportModal!: ScoreReportModalComponent;

  readonly dialog = inject(MatDialog);

  ngOnInit(): void {
    this.examsService.get('stages').subscribe({
      next: (response: { data: any[] }) => {
        //loop through the response data
        this.stages = response.data;

        if (this.authService.hasRole(ECargo.Comercial)) {
          this.stages = response.data.filter(
            (s) => s.stage_id == PLACEMENT_TEST_ID
          );
        }
      },
    });
  }

  // Methods
  scheduleExam(stage_id: number, name: string) {
    this.dialog.open(ScheduleExamDialogComponent, {
      data: {
        title: name,
        stageId: stage_id,
      },
    });
  }

  seeExamsResults(stageId: number, stageName: string) {
    this.dialog.open(ExamResultsDialogComponent, {
      data: {
        stageId,
        stageName,
      },
    });
  }
}

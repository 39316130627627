import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { faltasConsecutivas } from '../faltas-consecutivas/faltas-consecutivas.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-faltas-consecutivas-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
  ],
  templateUrl: './faltas-consecutivas-dialog.component.html',
  styleUrl: './faltas-consecutivas-dialog.component.scss',
})
export class FaltasConsecutivasDialogComponent {
  readonly data = inject<faltasConsecutivas>(MAT_DIALOG_DATA);

  columns = ['date', 'present'];

  formatDate(dateString: string): string {
    const date = new Date(dateString); // Create Date object from the input string

    // Adjust the time zone to GMT explicitly using toLocaleString()
    const formatado = date
      .toLocaleString('pt-BR', {
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC', // Set the time zone to UTC to avoid local time zone adjustments
      })
      .split(', ');

    return formatado[1] + ' - ' + formatado[0];
  }
}

import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { ColaboradorService } from '../../services/colaborador.service';
import { IColaborador } from '../../models/colaborador.model';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthService } from '../../services/auth.service';
import { SCHOOL_UNITS } from '../turma-dialog/turma-dialog.component';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { ProfilePictureUploadComponent } from '../profile-picture-upload/profile-picture-upload.component';

type option = Record<'id' | 'name', string | number>;

@Component({
  selector: 'app-colaborador-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule,
    ProfilePictureComponent
  ],
  templateUrl: './colaborador-dialog.component.html',
  styleUrl: './colaborador-dialog.component.scss',
})
export class ColaboradorDialogComponent {
  readonly dialogRef = inject(MatDialogRef<ColaboradorDialogComponent>);
  readonly colaborador? = inject<IColaborador>(MAT_DIALOG_DATA);
  readonly dialog = inject(MatDialog);

  apiService = inject(ApiService);
  colaboradorService = inject(ColaboradorService);
  protected authService = inject(AuthService);

  rolesList: { name: string; id: ECargo }[] = [
    { name: 'RH', id: ECargo.RH },
    { name: 'Comercial', id: ECargo.Comercial },
    { name: 'Professor(a)', id: ECargo.Professor },
    { name: 'Coodenador(a)', id: ECargo.Coordenador },
    { name: 'Administrador', id: ECargo.Adm },
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }

  openProfilePictureUploadDialog() {
    this.dialog.open(ProfilePictureUploadComponent, {
      data: {
        userId: this.colaborador?.id
      }
    });
  }

  unidades: option[] = SCHOOL_UNITS;

  form: FormGroup;
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      unitId: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      active: [true, [Validators.required]],
      email: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
    });

    if (this.colaborador) this.form.patchValue(this.colaborador);

    //IF is not admin nor HR
    if (!this.authService.hasRole([ECargo.Adm, ECargo.RH])) {

      // When inserting Colaborador, only allow to insert as professor in the current unidade
      if (!this.colaborador) {

        this.unidades = this.unidades.filter((school) => {
          return school.id == this.authService.currentUser.schoolId;
        });

        this.rolesList = this.rolesList.filter(role => {
          return [ECargo.Professor, ECargo.Comercial].includes(role.id)
        })
      }
      // When Updating Colaborador, only show the current role and unidade, do not allow change
      else {

        this.unidades = this.unidades.filter((school) => {
          return school.id == this.colaborador?.unitId;
        });

        this.rolesList = this.rolesList.filter(role => {
          return role.id == this.colaborador?.roleId
        })
      }
    }
  }

  putUser() {
    //Patch the values of colaborador
    let colaborador = { ...this.colaborador, ...this.form.value };

    this.dialogRef.close();
    this.colaboradorService.put(colaborador).subscribe(() => {
      this.colaboradorService
        .getAll(this.colaboradorService.pageIndex)
        .subscribe(
          this.colaboradorService.onLoad.bind(this.colaboradorService)
        );
    });
  }
}

@let isLoading = faltasConsecutivas.data.length === 0 && !notFound;

@if(isLoading){
<app-loading-screen></app-loading-screen>
}

<div [ngClass]="{'hidden': isLoading}">

    <div class="flex flex-col md:items-center gap-4 md:flex-row justify-between py-4">

        <h6>
            <!-- <mat-icon class="align-middle" [inline]="true">bar_chart</mat-icon> -->
            Controle de Faltas
        </h6>

        <div class="flex flex-col md:flex-row gap-4">

            <mat-form-field appearance="outline" class="no-padding">
                <mat-label>Período</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker (closed)="onFilter()"></mat-date-range-picker>

                @if (range.controls.start.hasError('matStartDateInvalid')) {
                <mat-error>Invalid start date</mat-error>
                }
                @if (range.controls.end.hasError('matEndDateInvalid')) {
                <mat-error>Invalid end date</mat-error>
                }
            </mat-form-field>

            <mat-form-field appearance="outline" class="md:w-40 no-padding">
                <mat-label>Faltas Consecutivas</mat-label>
                <input matInput type="text" [(ngModel)]="qtd_faltas" (ngModelChange)="onFilter()" type="number">
            </mat-form-field>


        </div>

    </div>

    <div class="card">


        @if(notFound) {
        <div class="my-16">
            <app-not-found></app-not-found>
        </div>
        }

        <table mat-table [dataSource]="faltasConsecutivas" [ngClass]="{'hidden': notFound}" class="table !shadow-none">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Aluno</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-3">
                    <app-profile-picture [user]="student" [shortenName]="mobileQueryService.matches">
                        <!-- <span class="text-light text-nowrap md:hidden">{{student.classroom_name}}</span> -->
                    </app-profile-picture>
                </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Telefone</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">
                    <p (click)="openWhatsapp(student.phone)" matTooltip="Abrir Whatsapp">
                        <span class="hover:text-blue-600 hover:underline">{{student.phone}}</span>
                    </p>
                </td>
            </ng-container>


            <ng-container matColumnDef="school">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Unidade</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">
                    <p>
                        <span>{{student.school}}</span>
                    </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="classroom">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Turma</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">
                    <p>
                        <span>{{student.classroom}}</span>
                    </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="faltas">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Faltas</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">
                    <div class="flex flex-row items-center gap-2">

                        <chip color="red">{{student.faltas_consecutivas}}</chip>

                        <button mat-icon-button (click)="$event.stopPropagation(); verDetalhes(student)">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="tratativa">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Tratativa</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">

                    <mat-checkbox [(ngModel)]="student.tratativa" (change)="handleAbsence(student.id, student.tratativa)"></mat-checkbox>

                </td>
            </ng-container>

            <!-- [routerLink]="['/turma', student.classroomId]" -->
            <tr mat-header-row *matHeaderRowDef="mobileQueryService.matches ? mobileColumns : desktopColumns"></tr>
            <tr mat-row *matRowDef="let student; columns: mobileQueryService.matches ? mobileColumns : desktopColumns;">
            </tr>
        </table>

        <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
        </mat-paginator>
    </div>

</div>
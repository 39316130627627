
<div class="flex flex-row items-center gap-2 " 
    [ngClass]="{
        'grayscale brightness-0': theme() != 'colored',
        'invert': theme() == 'light',
        'invert-1': theme() == 'dark'
    }">

    <img src="/assets/logo.png" alt="Logo USK" style="width: 35px;">
    <div class="flex flex-col">
        <h6 class="!mb-0" style="font-family:logomark; color: var(--primary); font-size: 2.1rem;">
            USK
        </h6>
        <p class="uppercase" style=" font-family: 'Nexa Heavy'; font-size: 0.5rem; line-height: 0.6rem; margin-left: 0.1rem;">Inglês Intensivo</p>
    </div>
</div>
export function formatDateTime(dateString: string): string {
    // Parse the input date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
    }

    // Extract date and time components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Return in Brazilian format
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

// Example usage
// const brazilianDate = formatToBrazilianDateTime("2024-11-26 20:51:58");
// console.log(brazilianDate); // Output: "26/11/2024 20:51:58"

import { Component, inject, input, model, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../services/api.service';
import { NgClass } from '@angular/common';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';
import { getShortName } from '../../models/colaborador.model';

export type turmaFeedback = {
  id?: number,
  comment: string;
  classroom_id: string;
  created_at?: string;
  reaction_count?: number;
  has_reacted?: boolean;
  user?:{
    id: string;
    name: string;
  }
  classroom?:{
    name: string,
    school: string,
    stage: number
  }
};

@Component({
  selector: 'app-turma-feedbacks',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    NgClass,
    ProfilePictureComponent
  ],
  templateUrl: './turma-feedbacks.component.html',
  styleUrl: './turma-feedbacks.component.scss',
})
export class TurmaFeedbacksComponent implements OnInit {
  classroomId = input.required<string>();
  private apiService = inject(ApiService);
  comment = model<string>();

  feedbacks: turmaFeedback[] = [];

  getShortName = getShortName;
  calcularTempoPassado = calcularTempoPassado;

  ngOnInit(): void {
    this.getComments()
  }

  onSubmitFeedback() {
    const comment = this.comment();

    if (!comment) return;

    const newFeedback: turmaFeedback = {
      comment,
      classroom_id: this.classroomId(),
    };

    this.comment.set('');
    this.apiService.post('classrooms/feedbacks', newFeedback).subscribe(() => {
      this.getComments();
    });
  }

  getComments() {
    this.apiService
      .getByFilters<turmaFeedback[]>('classrooms/feedbacks', {
        classroom_id: this.classroomId(),
      })
      .subscribe((comments) => {
        this.feedbacks = comments;
      });
  }
}

<h2 mat-dialog-title>
    Foto de perfil
</h2>

<mat-dialog-content>

    @if (previewUrl) {

    <img [src]="previewUrl" alt="User Profile Picture"
        class="w-44 aspect-square rounded-full object-cover object-center bg-gray-300 mx-auto">

    }@else {
    <button mat-flat-button (click)="fileInput.click()">
        <mat-icon class="mat-18">attach_file</mat-icon>
        Escolher arquivo
    </button>
    <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" class="hidden" />
    }

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
    @if(previewUrl){
    <button mat-flat-button (click)="onUpload()" [disabled]="!selectedFile || apiService.isLoading">Salvar</button>
    }
</mat-dialog-actions>
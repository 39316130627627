import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { LoadingComponent } from '../loading/loading.component';

type timeSheetDialogData = {
  teacherId?: string;
  name?: string;
  date: string;
  school?: string;
};

@Component({
  selector: 'app-timesheet-dialog',
  standalone: true,
  imports: [
    MatTableModule,
    MatDialogActions,
    MatDialogContent,
    MatButtonModule,
    MatDialogTitle,
    MatDialogClose,
    LoadingComponent,
  ],
  templateUrl: './timesheet-dialog.component.html',
  styleUrl: './timesheet-dialog.component.scss',
})
export class TimesheetDialogComponent implements OnInit {
  readonly data = inject<timeSheetDialogData>(MAT_DIALOG_DATA);
  protected timeSheetRecords: timeSheetRecord[] = [];

  private authService = inject(AuthService);
  private apiService = inject(ApiService);

  displayedColumns: (keyof timeSheetRecord)[] = [
    'date',
    'classroomName',
    'lastWord',
    'paragraph',
    'dictation',
    'reading',
    'stageId',
  ];

  ngOnInit(): void {
    this.getTimesheet();
  }

  getTimesheet() {
    const teacherId = this.data.teacherId;
    const name = this.data.name;

    const [month, year] = this.data.date ? this.data.date.split('/') : [undefined, undefined];

    const filters = {
      teacherId,
      month,
      year
    };

    this.apiService
      .getByFilters<any>('diario/timesheet', filters, false)
      .subscribe((timesheet) => {
        this.timeSheetRecords = timesheet.diaryList;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear().toString();

    return `${day}/${month}/${year}`;
  }
}

type timeSheetRecord = {
  date: string;
  teacher: string;
  classroomName: string;
  dictation: number;
  lastWord: string;
  paragraph: number;
  reading: number;
  stageId: number;
};

@if(colaborador){
<h2 mat-dialog-title>Editar Colaborador</h2>
}
@else if(authService.hasRole(authService.roles.Coordenador)){
<h2 mat-dialog-title>Novo Professor</h2>
}
@else {
<h2 mat-dialog-title>Novo Colaborador</h2>
}

<mat-dialog-content>
  
  <form [formGroup]="form" class="grid grid-cols-2 gap-x-4">
    
    <div class="col-span-2 flex flex-row gap-4 items-center mb-4">
      
        @if(colaborador){
          <div (click)="openProfilePictureUploadDialog()" class="cursor-pointer">
            <app-profile-picture [user]="colaborador" [hideName]="true" ></app-profile-picture>
          </div>
        }

      <mat-form-field class="w-full no-padding">
        <mat-label>Nome Completo</mat-label>
        <input matInput formControlName="name" spellcheck="false" />
      </mat-form-field>
    </div>
      
    <mat-form-field class="col-span-2">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="email" spellcheck="false" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>CPF</mat-label>
      <input matInput formControlName="cpf" spellcheck="false" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Gênero</mat-label>
      <mat-select formControlName="gender">
        <mat-option value="f">Feminino</mat-option>
        <mat-option value="m">Masculino</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Unidade</mat-label>
      <mat-select formControlName="unitId">
        @for (unidade of unidades; track unidade) {
        <mat-option [value]="unidade.id">{{unidade.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cargo</mat-label>
      <mat-select formControlName="roleId">
        @for (role of rolesList; track role) {
        <mat-option [value]="role.id">{{role.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @let isSelf = colaborador && authService.currentUser.id == colaborador.id;

    @if(!isSelf){
    <mat-slide-toggle formControlName="active">{{form.get('active')?.value? 'Ativo':'Inativo'}}</mat-slide-toggle>
    }

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button mat-flat-button (click)="putUser()" [disabled]="form.invalid || apiService.isLoading">
    {{colaborador ? 'Salvar': 'Adicionar'}}
  </button>
</mat-dialog-actions>
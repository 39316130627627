import { Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { getShortName } from '../../models/colaborador.model';
import { NgTemplateOutlet } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiService } from '../../services/api.service';
import { ProfilePictureService } from './profile-picture.service';
import { Subscription } from 'rxjs';

export type userProfileMinimalInfo = {
  id: string;
  name: string;
};

@Component({
  selector: 'app-profile-picture',
  standalone: true,
  imports: [MatTooltipModule, NgTemplateOutlet],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
})
export class ProfilePictureComponent implements OnInit, OnDestroy {
  private apiService = inject(ApiService);
  protected profilePictureService = inject(ProfilePictureService);

  user = input.required<{ id?: string; name?: string }>();

  size = input<'small' | 'normal' | 'big' | 'medium'>('normal');

  shortenName = input<boolean>(false);
  hideName = input<boolean>(false);

  picUrl: string = '';

  private profileSubscription?: Subscription;

  ngOnInit() {
    this.profileSubscription = this.profilePictureService.afterUpdate.subscribe(
      (profilePictureData) => {
        if (profilePictureData.id == this.user().id) {
          this.picUrl = profilePictureData.url;
        }
      }
    );

    this.picUrl = this.getPicUrl();
  }

  private getPicUrl() {
    return (
      this.apiService.profilePicEndpoint +
      '/storage/profile_picture/' +
      this.user().id
    );
  }

  ngOnDestroy(): void {
    this.profileSubscription?.unsubscribe();
  }

  getShortName = getShortName;
}

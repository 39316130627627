@if(users.length>0){
<div class="flex flex-col gap-4 mt-2">
    <h6>
        @if (users.length == 1) {
        1 Curtida
        }
        @else {
        {{users.length}} Curtidas
        }
    </h6>
    @for(user of users; track user){
    <app-profile-picture [user]="user"></app-profile-picture>
    }
</div>
}
@else {
<div class="flex justify-center p-5">
    <app-loading></app-loading>
</div>
}
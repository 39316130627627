import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { PessoaFormComponent } from '../../components/pessoa-form/pessoa-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { IAluno } from '../../models/aluno.model';
import { bindObjectPropsToCamelCase } from '../../utils/props-to-camel-case.util';
import { NgClass } from '@angular/common';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';
import { MatTooltipModule } from '@angular/material/tooltip';
import { formatDateTime } from '../../utils/format-date.util';

type Matricula = {
  student: IAluno;
  responsavel: any;
};

type Ocorrencia = {
  id: number;
  aluno_id: string;
  classroom_id: string;
  classroom_name: string;
  created_by: string;
  creator_name: string;
  school_name: string;
  created_at: string;
  description: string;
};

@Component({
  selector: 'app-aluno',
  standalone: true,
  imports: [
    PessoaFormComponent,
    MatTabsModule,
    ProfilePictureComponent,
    NgClass,
    LoadingScreenComponent,
    NotFoundComponent,
    MatTooltipModule
  ],
  templateUrl: './aluno.component.html',
  styleUrl: './aluno.component.scss',
})
export class AlunoComponent implements OnInit {
  private apiService = inject(ApiService);
  @ViewChild('alunoForm', { static: false }) alunoForm!: PessoaFormComponent;
  @ViewChild('respForm', { static: false }) respForm!: PessoaFormComponent;

  private route = inject(ActivatedRoute);

  protected aluno?: IAluno;
  protected responsavel?: any;
  protected ocorrencias: Ocorrencia[] = [];

  notFound: boolean = false;

  calcularTempoPassado = calcularTempoPassado
  formatDateTime = formatDateTime

  ngOnInit(): void {
    const alunoId = this.route.snapshot.params['id'];

    this.apiService
      .getByFilters<Matricula>('matricula/', {
        alunoId,
      })
      .subscribe({
        next: (matricula) => {
          this.alunoForm.personalInfoForm.patchValue(matricula.student);
          this.alunoForm.personalInfoForm.controls['birthdate'].setValue(
            new Date(matricula.student.birthdate ?? '')
          );
          this.alunoForm.isReadonly = true;

          this.aluno = bindObjectPropsToCamelCase(matricula.student);

          if (matricula.responsavel) {
            this.respForm.personalInfoForm.patchValue(matricula.responsavel);
            this.respForm.personalInfoForm.controls['birthdate'].setValue(
              new Date(matricula.responsavel.birthdate ?? '')
            );
            this.respForm.isReadonly = true;

            this.responsavel = bindObjectPropsToCamelCase(
              matricula.responsavel
            );
          }
        },
        error: () => {
          this.notFound = true;
        },
      });

    this.apiService
      .getByFilters<Ocorrencia[]>('students/occurrences', {
        aluno_id: alunoId,
      })
      .subscribe((ocorrencias) => {
        this.ocorrencias = ocorrencias;
      });
  }
}

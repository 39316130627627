<div class="flex flex-col gap-6 max-w-[50rem]" [ngClass]="{'hidden': feedbacks.length === 0}">
    @for (feedback of feedbacks; track feedback) {


    <div class="flex flex-col gap-4 card p-4">

        <div class="flex flex-row gap-4 ">

            <div class="relative w-10">
                <img [src]="'/assets/book-cover-' + (feedback.classroom?.stage ?? 1) + '.jpg'" alt="Book cover"
                    class="w-10  rounded">

                <app-profile-picture [user]="feedback.user??{}" [hideName]="true" size="small"
                    class="!w-8 absolute -bottom-1 -right-2 border-2 border-white rounded-full">
                </app-profile-picture>
            </div>

            <div class="flex flex-col overflow-hidden">
                <div class="flex flex-row gap-2 items-center text-nowrap">
                    <p class="font-medium">{{feedback.classroom?.name}}</p>
                    <p class="text-light text-sm">
                        {{feedback.classroom?.school}}
                    </p>
                    <p class="font-medium text-sm !text-blue-700">
                        <mat-icon [inline]="true" class="align-bottom">star</mat-icon>
                        Feedback
                    </p>
                </div>
                <div class="flex flex-row gap-2 items-center text-nowrap">
                    <p class="font-medium text-sm">{{getShortName(feedback.user?.name)}}</p>
                    <p class="text-light text-xs">{{calcularTempoPassado(feedback?.created_at??'')}}</p>
                </div>
            </div>

        </div>

        <pre class="text-light whitespace-break-spaces " style="font-family: inherit;"
            [innerText]="feedback.comment"></pre>

        <div class="flex items-center justify-between">
            <button mat-button class="!rounded-full !overflow-hidden" [disabled]="apiService.isLoading"
                (click)="reactToFeedback(feedback.id)">
                <div class="flex items-center gap-2">
                    @if(feedback.has_reacted) {
                    <img src="/assets/heart-red.png" width="20px">
                    <span class="text-red-600">Curtir</span>

                    }
                    @else {
                    <img src="/assets/heart-outline.png" width="20px" class="opacity-80">
                    <span class="text-light">Curtir</span>
                    }
                </div>
            </button>

            @if(feedback.reaction_count && feedback.reaction_count > 0){
            <button mat-button class="!rounded-full !overflow-hidden" [disabled]="apiService.isLoading"
                (click)="checkReactions(feedback.id)">
                <div class="flex items-center gap-1">
                    <span class="text-light font-normal">{{feedback.reaction_count}}</span>
                    <span class="text-light font-normal">{{feedback.reaction_count == 1 ? 'Curtida' :
                        'Curtidas'}}</span>
                    <mat-icon class="text-light" [inline]="true" style="font-size: 1.2rem;">chevron_right</mat-icon>
                </div>
            </button>
            }
        </div>

    </div>

    }
</div>
import {
  Component,
  inject
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { ChipComponent } from '../../components/chip/chip.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { MapaDeRiscoService } from './mapa-de-risco.service';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export enum ERiskClassification {
  BAIXO = 1,
  MEDIO = 2,
  ALTO = 3,
}

export type studentRiskMap = {
  average_attendance: number;
  average_performance: number;
  name: string;
  risk_classification: ERiskClassification;
};

@Component({
  selector: 'app-mapa-de-risco',
  standalone: true,
  imports: [
    MatTableModule,
    ChipComponent,
    ProfilePictureComponent,
    NgTemplateOutlet,
    NgClass,
    MatPaginatorModule,
    LoadingComponent,
    LoadingScreenComponent,
    NotFoundComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './mapa-de-risco.component.html',
  styleUrl: './mapa-de-risco.component.scss',
})
export class MapaDeRiscoComponent extends BasePaginatedComponent  {

  protected override paginatedService = inject(MapaDeRiscoService);

  protected ERiskClassification = ERiskClassification;
  protected mobileQueryService = inject(MobileQueryService)

  desktopColumns: string[] = [
    'name',
    'school',
    'classroom',
    'attendance',
    'performance',
    'risk',
  ];

  mobileColumns: string[] = [
    'name',
    'risk',
  ]

}
import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  ProfilePictureComponent,
  userProfileMinimalInfo,
} from '../profile-picture/profile-picture.component';
import { LoadingComponent } from '../loading/loading.component';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-reactions-bottom-sheet',
  standalone: true,
  imports: [ProfilePictureComponent, LoadingComponent],
  templateUrl: './reactions-bottom-sheet.component.html',
  styleUrl: './reactions-bottom-sheet.component.scss',
})
export class ReactionsBottomSheetComponent implements OnInit {
  apiService = inject(ApiService);

  users: userProfileMinimalInfo[] = [];

  readonly data = inject<{ feedbackId: number }>(
    MAT_BOTTOM_SHEET_DATA
  );


  ngOnInit(): void {
    this.apiService
      .get<userProfileMinimalInfo[]>(`classrooms/feedbacks/${this.data.feedbackId}/reactions`)
      .subscribe((users) => {
        this.users = users;
      });
  }
}

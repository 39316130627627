import { Component, input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartType, registerables } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [CommonModule, BaseChartDirective],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent implements OnInit {
  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.barChartData = {
      labels: this.labels(),
      datasets: [
        {
          label: 'Alunos Presentes',
          data: this.data(),
          backgroundColor: '#9155fd',
          borderWidth: 0,
          borderStyle: 'dotted',
          borderRadius: 10,
        },
      ],
    };

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false, // Ensures the chart adjusts to the container's aspect ratio
      scales: {
        x: { beginAtZero: true },
        y: {
          suggestedMax: this.max(),
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      },
    };
  
  }


  max = input.required<number>();
  data = input<number[]>();

  labels = input<string[]>();

  barChartType: any = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: any = {};
  barChartOptions: any = {};
}

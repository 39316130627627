import { Component, HostListener, inject, model } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { NgClass, NgStyle } from '@angular/common';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ApiService } from '../../services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NetworkStatusService } from '../../services/network-status.service';
import { NotFoundComponent } from '../../components/not-found/not-found.component';

function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    LogomarkComponent,
    ReactiveFormsModule,
    NgStyle,
    LoadingComponent,
    MatIconModule,
    NotFoundComponent,
    NgClass,
    MatCheckboxModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private authService = inject(AuthService);
  protected apiService = inject(ApiService);
  protected networkStatusService = inject(NetworkStatusService);

  readonly rememberMe = model(true);


  hidePassword: boolean = true;

  bgImgs: string[] = ['classroom', 'london', 'callan-method', 'snooker'];
  bgImg: string;

  loginForm: FormGroup;

  login() {
    if(!this.apiService.isLoading)
      this.authService.login(
        this.loginForm.get('username')?.value,
        this.loginForm.get('password')?.value
      );
  }

  constructor(private _formBuilder: FormBuilder) {
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.bgImg = getRandomElement(this.bgImgs);
  }

  showInstallButton = false;
  deferredPrompt: any;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    event.preventDefault(); // Prevent the mini-info bar from appearing on mobile
    this.deferredPrompt = event;
    this.showInstallButton = true; // Show the install button
  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null; // Clear the prompt
        this.showInstallButton = false; // Hide the install button
      });
    }
  }
}

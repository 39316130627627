import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type profilePictureData = { id: string; url: string };

@Injectable({
  providedIn: 'root',
})
export class ProfilePictureService {
  private subject = new Subject<any>();

  // Observable for subscribers
  afterUpdate: Observable<profilePictureData> = this.subject.asObservable();

  // Emit new data
  update(data: profilePictureData): void {
    this.subject.next(data);
  }
}

<div class="mb-4 gap-4 flex flex-col-reverse md:flex-row">

  <div class="card w-full md:w-2/3 p-4 flex flex-col justify-between">
    <h6 class="!mb-0">Atividades</h6>

    <app-live-board class="min-h-20 md:h-full">

      @for(message of liveBoardMessages; track message ){
      <p class="text-sm !mt-3" [innerHTML]="message"></p>
      }

    </app-live-board>

    <div class="flex flex-col gap-y-6 md:flex-row w-full justify-between">

      <app-statistic color="purple" label="Avaliações" icon="edit" (click)="openAssessmentDialog()">
        @if (weeklyAssessmentsComplete != undefined && totalClassrooms != undefined) {
        <span class="slide-in text-light">
          {{weeklyAssessmentsComplete}}/{{totalClassrooms}}
        </span>
        }
      </app-statistic>

      <app-statistic color="green" label="New Work" icon="videocam" (click)="openVideoDialog(videoTypes.NEW_WORK)">
        @if(newWorkUploaded != undefined){
        <span class="slide-in text-light">
          {{newWorkUploaded}}/{{expectedUploads}}
        </span>
        }
      </app-statistic>

      <app-statistic color="primary" label="Revision" icon="videocam" (click)="openVideoDialog(videoTypes.REVISION)">
        @if(revisionUploaded != undefined){
        <span class="slide-in text-light">
          {{revisionUploaded}}/{{expectedUploads}}
        </span>
        }
      </app-statistic>

      <app-statistic color="yellow" label="Pendências" icon="schedule">
        <span class="text-light">
          0h
        </span>
      </app-statistic>
    </div>

  </div>

  <div class="card max-md:w-full p-4 w-1/3 bg-right-bottom bg-contain bg-no-repeat
          flex flex-row justify-center items-center bg-[url(/assets/triangle-light.png)]">

    @if(timesheet){
    <div class="w-2/3 flex flex-col gap-2 h-full justify-between">

      <h6>{{timesheet.currentMonth}}</h6>

      <p class="text-sm text-light">
        Aulas Ministradas
      </p>

      <h6 style="font-size: 1rem; color: var(--green)">
        <mat-icon class="align-middle">trending_up</mat-icon>
        {{timesheet.totalClasses}} horas
      </h6>

      <div>
        <button mat-raised-button class="button-squared bg--green" (click)="openTimesheetDialog()"
          [disabled]="timesheet.totalClasses == 0 && !authService.hasRole([authService.roles.Adm, authService.roles.Coordenador])">
          <mat-icon>search</mat-icon>
          Ver lançamentos
        </button>
      </div>

    </div>

    <div class="w-1/3 flex items-end justify-center">
      @switch (timesheet.currentMonth) {
        @case ('December') {
          <a [attr.href]="'https://santatracker.google.com/intl/en-US/'" target="_blank" rel="noopener noreferrer">
            <img src="/assets/gift-box.png" alt="Book cover" class="w-10/12 slide-in hover:animate-bounce">
          </a>
        }
        <!-- Include:
        - saint patricks day
        - hallowen -->
        @default {
          <img src="/assets/calendar.png" alt="Book cover" class="w-10/12">
        }
      }
    </div>
    }
    @else {
      <div class="max-md:min-h-36 flex justify-center items-center">
        <app-loading shape="circle" ></app-loading>
      </div>
    }
  </div>


</div>